<template>
    <div class="detailWarp">
        <div class="goBack">
            <div class="back" @click="$router.push('/orderlist')">返回</div>
            <div class="nav">
                <router-link to="/">首页</router-link> &gt; <router-link to="/orderlist">我的订单</router-link> &gt;
                <span>订单详情</span>
            </div>
        </div>
        <div class="orderStatus">
            <div class="statusLeft">订单状态</div>
            <div class="statusRight"><span>请在<i>15分00秒</i>内支付完毕</span> 已支付</div>
        </div>
        <div class="shopDetail">
            <h3>商品信息</h3>
            <p class="shopClass">商品类型：课程</p>
            <div class="shop">
                <img src="../assets/images/shop.png" alt="">
                <p class="shopTitle">21考研考前英语阅读、写作技巧精讲班通过725省考预判822多判省通过率省考725省考预判822多判省通过率省考</p>
                <div class="shopPrice">
                    <p><b></b><span>￥999.99</span></p>
                    <p><b>双11优惠：</b><span>-￥99.99</span></p>
                    <p><b class="realityPrice">实付：</b><span class="price">￥40</span></p>
                </div>
            </div>
        </div>
        <div class="orderInfo">
            <h3>订单信息</h3>
            <p>订单编号：XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX</p>
            <p>支付时间：2022-08-05 18:23:23</p>
            <p>支付方式：支付宝</p>
        </div>
        <div class="orderBtn">
            <el-button round class="deleteOrder">删除订单</el-button>
            <el-button type="primary" round>重新下单</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'orderdetail'
}
</script>

<style lang="scss" scoped>
.detailWarp {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
    color: #333;

    .orderBtn {
        text-align: right;
        margin-top: 20px;
    }

    .deleteOrder {
        &:hover {
            background: #fff;
            border: 1px solid var(--change-color);
            color: var(--change-color)
        }
    }

    .goBack {
        display: flex;

        .back {
            width: 52px;
            height: 28px;
            border: 1px solid #dfdfdf;
            border-radius: 14px;
            text-align: center;
            line-height: 28px;
            font-size: 14px;
            color: #888;
            cursor: pointer;
        }

        .nav {
            line-height: 28px;
            flex: 1;
            font-size: 14px;
            color: #888;
            padding-left: 14px;

            a {
                color: #888;
            }
        }
    }

    .orderStatus {
        height: 50px;
        background: #F5F7FA;
        margin-top: 12px;
        display: flex;

        .statusLeft {
            flex: 1;
            line-height: 50px;
            font-size: 16px;
            padding-left: 20px;
            font-weight: bold;
        }

        .statusRight {
            flex: 1;
            line-height: 50px;
            text-align: right;
            padding-right: 20px;

            span {
                font-size: 14px;

                i {
                    color: #94070A;
                }
            }
        }
    }

    .shopDetail {
        height: 182px;
        padding: 20px;
        border: 1px solid #EFF2F5;

        h3 {
            font-size: 16px;
            font-weight: normal;
        }

        .shopClass {
            font-size: 14px;
            line-height: 34px;
        }
    }

    .shop {
        display: flex;
        margin-top: 4px;

        img {
            width: 200px;
            height: 114px;
            border-radius: 4;
        }

        .shopTitle {
            flex: 5;
            font-size: 16px;
            line-height: 28px;
            padding: 10px 0 0 24px;
        }

        .shopPrice {
            flex: 3;
            text-align: right;

            p {
                display: flex;
                font-size: 14px;
                line-height: 28px;

                span {
                    flex: 1;
                }

                b {
                    flex: 2;
                    font-weight: normal;
                }

                .realityPrice {
                    font-size: 16px;
                    font-weight: bold;
                }

                .price {
                    font-size: 16px;
                    color: #94070A;
                    font-weight: bold;
                }
            }


        }
    }

    .orderInfo {
        border: 1px solid #EFF2F5;
        padding: 10px 20px 20px 20px;
        margin-top: 10px;

        p {
            font-size: 12px;
            line-height: 24px;
        }

        h3 {
            font-size: 16px;
            font-weight: normal;
            line-height: 36px;
        }
    }
}
</style>